var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.successfulChangedPassword
        ? _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onPasswordResetSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("passwordReset.resetPasswordTitle")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("passwordReset.newPasswordLabel")))
                      ])
                    ]
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "7" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "new-password-group" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "new-password-input",
                              name: "new-password-input",
                              type: "password",
                              state: _vm.validateState("newPassword"),
                              "aria-describedby": "new-password-live-feedback"
                            },
                            model: {
                              value: _vm.$v.form.newPassword.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.form.newPassword,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.newPassword.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "new-password-live-feedback" } },
                            [
                              !_vm.$v.form.newPassword.required
                                ? _c("div", { staticClass: "error" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("login.requiredPassword")
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.$v.form.newPassword.minLength
                                ? _c("div", { staticClass: "error" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("register.passwordMinLength", {
                                            pwLength:
                                              _vm.$v.form.newPassword.$params
                                                .minLength.min
                                          })
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("passwordReset.newPasswordConfirmLabel")
                          )
                        )
                      ])
                    ]
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "7" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "confirm-new-password-group" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "confirm-new-password-input",
                              name: "confirm-new-password-input",
                              type: "password",
                              state: _vm.validateState("confirmNewPassword"),
                              "aria-describedby":
                                "confirm-new-password-live-feedback"
                            },
                            model: {
                              value: _vm.$v.form.confirmNewPassword.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.form.confirmNewPassword,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.confirmNewPassword.$model"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              attrs: {
                                id: "confirm-new-password-live-feedback"
                              }
                            },
                            [
                              !_vm.$v.form.confirmNewPassword.sameAsPassword
                                ? _c("div", { staticClass: "error" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("register.passwordMismatch")
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.$v.form.confirmNewPassword.required
                                ? _c("div", { staticClass: "error" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("passwordReset.notEmpty")
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "4", sm: "7" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            type: "submit",
                            variant: "success"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("passwordReset.button")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("h2", [_vm._v(" " + _vm._s(_vm.$t("passwordReset.success")))]),
              _c(
                "b-button",
                {
                  attrs: { block: "", variant: "success", to: "/webmag-login" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("passwordReset.goToLogin")) + " ")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }