<template>
  <div>
    <b-form @submit.stop.prevent="onPasswordResetSubmit" v-if="!successfulChangedPassword">
      <b-row class="mb-4">
        <b-col cols="12">
          <h2>{{ $t('passwordReset.resetPasswordTitle') }}</h2>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12" offset-sm="1" sm="3">
          <label>{{ $t('passwordReset.newPasswordLabel') }}</label>
        </b-col>
        <b-col cols="12" sm="7">
          <b-form-group id="new-password-group">
            <b-form-input
              id="new-password-input"
              name="new-password-input"
              type="password"
              v-model.trim="$v.form.newPassword.$model"
              :state="validateState('newPassword')"
              aria-describedby="new-password-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="new-password-live-feedback">
              <div class="error" v-if="!$v.form.newPassword.required">
                {{ $t('login.requiredPassword') }}
              </div>
              <div class="error" v-if="!$v.form.newPassword.minLength">
                {{
                  $t(
                    'register.passwordMinLength',
                    {pwLength: $v.form.newPassword.$params.minLength.min},
                  )
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12" offset-sm="1" sm="3">
          <label>{{ $t('passwordReset.newPasswordConfirmLabel') }}</label>
        </b-col>
        <b-col cols="12" sm="7">
          <b-form-group id="confirm-new-password-group">
            <b-form-input
              id="confirm-new-password-input"
              name="confirm-new-password-input"
              type="password"
              v-model.trim="$v.form.confirmNewPassword.$model"
              :state="validateState('confirmNewPassword')"
              aria-describedby="confirm-new-password-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="confirm-new-password-live-feedback">
              <div class="error" v-if="!$v.form.confirmNewPassword.sameAsPassword">
                {{ $t('register.passwordMismatch') }}
              </div>
              <div class="error" v-if="!$v.form.confirmNewPassword.required">
                {{ $t('passwordReset.notEmpty') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12" offset-sm="4" sm="7">
          <b-button block type="submit" variant="success">
            {{ $t('passwordReset.button') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <h2> {{ $t('passwordReset.success') }}</h2>
      <b-button block variant="success" to="/webmag-login">
        {{ $t('passwordReset.goToLogin') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import auth from '@/plugins/firebase';
import { validationMixin } from 'vuelidate';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'ResetPasswordForm',
  mixins: [validationMixin],
  props: {
    actionCode: {
      required,
    },
  },
  data() {
    return {
      form: {
        newPassword: null,
        confirmNewPassword: null,
      },
      successfulChangedPassword: false,
    };
  },
  validations: {
    form: {
      newPassword: {
        required,
        minLength: minLength(8),
      },
      confirmNewPassword: {
        required,
        sameAsPassword: sameAs('newPassword'),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onPasswordResetSubmit() {
      await this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        await auth.confirmPasswordReset(this.actionCode, this.form.newPassword);
        await auth.signOut();
        this.successfulChangedPassword = true;
      } catch (err) {
        console.error(err);
        console.log(err.code);
      }
    },
  },
};
</script>
